import type { BuildInfo } from '@common/models/build-info.model';

export const buildInfo: BuildInfo = {
    commitSHA: '4455c5c7afeb7ca2c3966030ec2ea5d858187705',
    branch: 'main',
    deployEnv: 'prod',
    version: '1.2.4',
    dateFormatted: '2025-02-09--21-40',
    dateISOString: '2025-02-09T21:40:11.269Z',
};
